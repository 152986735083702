import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SvgIconComponent {
  @Input() public icon: SVGIcon | string = '';
  @Input() title: string | null = null;
  public id = this.environment.appVersion;

  constructor(@Inject(ENVIRONMENT_TOKEN) public environment: Environment) {}
}

type SVGIcon =
  | ''
  | 'dashboard'
  | 'learning'
  | 'courses'
  | 'course-catalog'
  | 'community'
  | 'skills'
  | 'career'
  | 'support'
  | 'full-immersion-tools'
  | 'home'
  | 'business'
  | 'close'
  | 'thin_arrow'
  | 'dd_expander'
  | 'course_complete'
  | 'course_optional'
  | 'failed_exam'
  | 'pending_exam'
  | 'course_multimedia'
  | 'course_audio_b'
  | 'course_download_b'
  | 'course_exam_b'
  | 'course_multimedia_b'
  | 'course_pdf_b'
  | 'course_survey_b'
  | 'course_text_b'
  | 'course_video_b'
  | 'course_quiz_b'
  | 'thumbs_up'
  | 'course_locked'
  | 'course_unlocked'
  | 'course_locked_filled'
  | 'course_unlocked_filled'
  | 'long_arrow'
  | 'reload'
  | 'star'
  | 'checkbox'
  | 'union'
  | 'zap'
  | 'book'
  | 'exam'
  | 'checkbox_thin'
  | 'linkedin'
  | 'pencil'
  | 'no_content'
  | 'expand'
  | 'collapse'
  | 'hidden'
  | 'edit'
  | 'calendar'
  | 'filter'
  | 'question_circle'
  | 'chart'
  | 'complexity-level1'
  | 'complexity-level2'
  | 'complexity-level3'
  | 'complexity-level4'
  | 'complexity-level5'
  | 'envelope'
  | 'processing-graphic'
  | 'processing-union'
  | 'processing-chart'
  | 'processing-calendar'
  | 'system-check'
  | 'case'
  | 'cfi-logo'
  | 'cfi-logo-bars'
  | 'starburst'
  | 'alert'
  | 'certificate'
  | 'rounded-alert'
  | 'eye'
  | 'eye-closed'
  | 'moon'
  | 'blockquote'
  | 'rounded-download'
  | 'rounded-lock'
  | 'squared-download'
  | 'rounded-ribbon'
  | 'squared-link'
  | 'info'
  | 'rounded-linkedin'
  | 'fire'
  | 'fire-large'
  | 'share'
  | 'topic'
  | 'cfi-teams-logo'
  | 'paperplane'
  | 'assigned'
  | 'mobile'
  | 'msg_thumbs_up'
  | 'search'
  | 'note'
  | 'ico_fmva'
  | 'ico_ftip'
  | 'ico_cmsa'
  | 'ico_bida'
  | 'ico_fpwm'
  | 'ico_spec'
  | 'ico_bundle'
  | 'ico_courses'
  | 'ico_templates'
  | 'ico_resources'
  | 'ico_pages'
  | 'ico_search'
  | 'ico_author'
  | 'ico_lesson'
  | 'checkmark'
  | 'triangle-alert'
  | 'rounded-checkbox'
  | 'checkbox_new'
  | 'assigned_training'
  | 'case_career'
  | 'cert_menu'
  | 'error'
  | 'link'
  | 'minus_circle'
  | 'information_filled'
  | 'nasba_assessment'
  | 'nasba_field'
  | 'nasba_credits'
  | 'nasba_date'
  | 'lighting';
